<!-- 找回预存红包 -->
<template>
  <div class="yucun_container">
    <img class="yucun_close_icon" @click="handleCancel" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210909161721976.jpg" alt="">
    <div class="success" v-if="success">
      <van-icon name="checked" color="#ff98a0" size="40" />
      <div class="yucun_title">预存红包找回成功</div>
      <div class="amount">您的预存红包为：<span>￥{{ dataInfo.carveupAmount }}</span></div>
      <van-button type="danger" color="#ff98a0" round @click="handleCancel">我知道了</van-button>
    </div>
    <template v-else>
      <div class="yucun_title">找回预存红包</div>
      <div class="yucun_item yucun_item_1">{{phone}}</div>
      <div class="yucun_item">
        <input v-model="code" type="number" placeholder="请填写短信验证码">
        <div @click="getPreStoreCode" class="yucun_getcode">{{time == 0 ? '获取验证码' : time + 's再次发送'}}
        </div>
      </div>
      <div v-if="dataInfo" class="yucun_info_box">
        <img class="yucun_info_head" :src="dataInfo.headImg ? dataInfo.headImg : 'http://th5.rolormd.com/img/100.b3582921.png'" alt="">
        <div class="yucun_info_content">
          <div>{{dataInfo.agentName}}</div>
          <div v-if="dataInfo.carveupAmount == 0">您暂无可找回的预存红包噢</div>
          <div v-if="dataInfo.carveupAmount > 0">原有预存红包： ¥{{dataInfo.carveupAmount}}</div>
        </div>
      </div>
      <div @click="findPrestore" v-if="!dataInfo" class="yucun_search">查找预存红包</div>
      <template v-else>
        <div v-if="dataInfo.carveupAmount > 0" @click="sureFind" class="yucun_btn">确认找回</div>
        <div v-if="dataInfo.carveupAmount == 0" @click="handleCancel" class="yucun_btn">返回</div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    phone: {
      type: String,
      default: ''
    }
  },
  name: '',
  data() {
    return {
      time: 0,
      code: '',
      dataInfo: null,
      success: false
    }
  },
  created() {

  },
  methods: {
    //获取找回预存红包的手机验证码
    getPreStoreCode: function () {
      if (this.time !== 0) return
      this.time = 60;
      let timer = setInterval(function () {
        this.time--
        if (this.time == 0) {
          clearInterval(timer)
          timer = null
        }
      }, 1000)
      this.get("/base/ToolsS/SendMobileMsg", {
        mobile: this.phone,
        phoneType: '86',
        language: 1
      }).then(res => {
        if (res.code === 1) {
          this.$toast("发送成功!")
        }
      })

    },
    //查找预存红包
    findPrestore: function () {
      if (!this.code) return this.$toast('请输入正确的验证码!')
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true })
      this.get("/OrderGoods/Order/GetCarveupAmount", {
        phone: this.phone,
        validCode: this.code,
      }, 2).then(res => {
        if (res.code === 1) {
          this.$toast.clear();
          this.code = '';
          this.dataInfo = res.response;
        } else {
          this.$toast(res.msg)
        }
      })

    },
    //确认找回
    sureFind() {
      this.$toast.loading({ message: '找回中...', duration: 0, overlay: true })
      this.get("/OrderGoods/Order/GetBackCarveup", {
        phone: this.phone,
      }, 2).then(res => {
        if (res.code === 1) {
          this.$toast.clear();
          this.success = true;
          this.$emit("success", this.dataInfo.carveupAmount);
        } else {
          this.$toast(res.msg)
        }
      })
    },
    handleCancel() {
      this.$emit("cancel");
    }
  }
}
</script>
<style lang='scss' scoped>
.yucun_container {
  padding: 15px;
  width: 280px;
  background-color: #fff;

  .success {
    .yucun_title {
      color: #666;
      padding: 10px 0;
      font-size: 16px;
    }
    .amount {
      margin-bottom: 30px;
      color: #999;
      span {
        color: #ff98a0;
      }
    }
    .van-button {
      width: 100%;
      height: 38px;
    }
  }
  .yucun_info_box {
    width: 100%;
    padding: 10px;
    background-color: rgb(248, 248, 248);
    border-radius: 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 20px;

    .yucun_info_head {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 15px;
    }

    .yucun_info_content {
      color: #999;
    }
  }

  .yucun_item {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    color: #666;

    input {
      border: none;
    }
  }

  .yucun_title {
    color: #333;
    font-size: 14px;
    text-align: center;
  }

  .yucun_getcode {
    padding: 5px 12px;
    border-radius: 500px;
    color: #fff;
    background-color: #ff98a0;
  }

  .yucun_item_1 {
    margin-top: 25px;
  }

  .yucun_close_icon {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
  }

  .yucun_search {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 500px;
    box-sizing: border-box;
    border: 1px solid #eee;
    color: #666;
    text-align: center;
    margin-top: 25px;
  }

  .yucun_btn {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 500px;
    box-sizing: border-box;
    background-color: #ff98a0;
    color: #fff;
    text-align: center;
    margin-top: 25px;
  }
}
</style>